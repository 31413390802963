<template>
  <div>
    <div>
      <div class="title"><h3>الهيكل التنظيمي للشركة</h3></div>
      <v-divider></v-divider>
      <v-container>
        <v-row>
          <h4 class="blue--text">
            شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد
          </h4>
        </v-row>

        <v-row class="mt-5">
          <v-col class="subClass" cols="12" sm="12" xs="12" lg="12" md="12">
            <div>
              <div>
                <h2>الهيكل التنظيمي التابع لرئيس مجلس الإدارة</h2>
                <p class="blue--text">
                  لتحميل الهيكل التنظيمي للشركة أضغط هنا
                  <a
                    href="https://backend.ascww.org/api/admin-structure/download"
                    ><v-icon class="green--text">mdi-file</v-icon></a
                  >
                </p>
                <div id="app">
                  <v-col
                    class="subClass"
                    cols="12"
                    sm="12"
                    xs="12"
                    lg="12"
                    md="12"
                  >
                    <PDFJSViewer
                      path="https://backend.ascww.org/api/admin-structure"
                      :fileName="`${name}`"
                    />
                  </v-col>
                </div>
              </div>
              <br />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import PDFJSViewer from "../../components/PDFJSViewer.vue";
export default {
  components: {
    PDFJSViewer,
  },
  data() {
    return {
      name: "organizationStructure.pdf", //change which pdf file loads
      //path: "lib/web/viewer.html", //path of the PDF.js viewer.html
    };
  },
};
</script>
<style>
.Labimg {
  box-shadow: gray 3px 3px 3px 3px;
  border-image: 5px white;
}
.divider {
  width: 2px;
  background: #1f659b;

  height: 100%;
  margin-right: 10px !important;

  margin-top: 30px;
  margin-bottom: 30px;
}
.changeTitleStyleTrain {
  background: rgba(222, 184, 135, 0.192) !important;
  color: gray !important;
}
@media screen and (max-width: 1260px) {
  .divider {
    width: 0px !important;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
h1 {
  color: #1f659b;
}
</style>
